.usa-combo-box__list, .usa-combo-box__input, .usa-input-group, .usa-input,
.usa-textarea, .usa-range, .usa-select, .usa-checkbox__label,
.usa-radio__label, .usa-fieldset,
.usa-hint{
  font-family:Source Sans Pro Web, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
  font-size:1.06rem;
  line-height:1.3;
}

.usa-combo-box__input, .usa-input-group, .usa-input,
.usa-textarea, .usa-range, .usa-select{
  border-width:1px;
  border-color:#565c65;
  border-style:solid;
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
  border-radius:0;
  color:#1b1b1b;
  display:block;
  height:2.5rem;
  margin-top:0.5rem;
  max-width:30rem;
  padding:0.5rem;
  width:100%;
}

.usa-input--success.usa-combo-box__input, .usa-input--success.usa-input-group, .usa-input--success.usa-input,
.usa-input--success.usa-textarea, .usa-input--success.usa-range, .usa-input--success.usa-select{
  border-width:0.25rem;
  border-color:#00a91c;
  border-style:solid;
}


.usa-select{
  background-repeat:no-repeat;
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
  background-color:white;
  background-position:right 0.5rem center;
  background-size:1.25rem;
  padding-right:2rem;
}
.usa-select::-ms-expand{
  display:none;
}
.usa-select:-webkit-autofill{
  -webkit-appearance:menulist;
          appearance:menulist;
}
.usa-select:-moz-focusring{
  color:transparent;
  text-shadow:0 0 0 black;
}
@media (forced-colors: active){
  .usa-select{
    -webkit-appearance:listbox;
       -moz-appearance:listbox;
            appearance:listbox;
    background-image:none;
    padding-right:0;
  }
}

.usa-form .usa-input,
.usa-form .usa-range,
.usa-form .usa-select,
.usa-form .usa-textarea{
  max-width:none;
}